import { getResourceInfo } from '@/api';
import { EXPIRES_PARAM } from '@/consts';
import { ExtendFileInfo } from '@/stores/cvc';
import { formatSToFullTime } from '@/util/formatter';
import { fromNowBySecond } from '@/util/time';
import { getParam } from '@/util/url';
import { useCallback } from 'react';

const useDownload = <E extends ExtendFileInfo & { resource_id?: string }>() => {
  const onDownload = useCallback(async (downloadList: E[]) => {
    const list = downloadList.filter((file) => !file.isFetching);
    if (!list.length) {
      return;
    }
    const items = await Promise.all(
      list.map(async (item) => {
        /**
         * @workaround 다운로드시 expired된 파일을 업데이트할떄, resource_id가 필요함.
         * result의 경우, resource_id와 id가 일치하지 않음(processing되서 나오는 파일과 아이템 생성시 아이디가 다르기 때문)
         * resource_id가 없는 경우, id를 사용하도록 수정.
         */
        const {
          data: { data },
        } = await getResourceInfo(item.resource_id || item.id);
        /**
         * @workaround
         * URL 에서 Expires 파라미터를 추출하여, 남은 시간을 계산한다.
         * 숨겨진 STS credentials 의 값과 동일하게 유지되어야 해당 코드가 올바르게 동작한다.
         * @see https://supertone-inc.slack.com/archives/C04FBAMV202/p1704187894618109?thread_ts=1704171653.276699&cid=C04FBAMV202
         */
        const originalExpires = getParam(data.original.url, EXPIRES_PARAM);
        return {
          ...item,
          originalUrl: data.original.url,
          transcodedUrl: data.transcoded[0].url,
          duration: data.original.metadata.props.duration,
          originalExpires: originalExpires && fromNowBySecond(originalExpires),
        };
      })
    );
    const downloadItems = list.map((item) => {
      const target = items.find((i) => i.id === item.id);
      return target || item;
    });
    const downloadNumberMap: Record<string, number> = {};
    const downloadFilesSequentially = async () => {
      for (const file of downloadItems) {
        const response = await fetch(file.originalUrl as string);
        const blob = await response.blob();
        let { name } = file;
        const exec = file.name.match(/\.[0-9a-z]+$/i)?.[0];
        if (downloadNumberMap[file.name]) {
          name = `${name.replace(/\.[^/.]+$/, '')} ${formatSToFullTime(
            Date.now()
          )}(${downloadNumberMap[file.name]})${exec}`;
          downloadNumberMap[file.name] = downloadNumberMap[file.name]! + 1;
        } else {
          name = `${name.replace(/\.[^/.]+$/, '')} ${formatSToFullTime(
            Date.now()
          )}${exec}`;
          downloadNumberMap[file.name] = 1;
        }
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = name;
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
        await new Promise((resolve) => setTimeout(resolve, 200)); // 200ms interval
      }
    };
    await downloadFilesSequentially();
    return items;
  }, []);
  return {
    onDownload,
  };
};
export default useDownload;
